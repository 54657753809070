.app {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.half {
  width: 50vw;
  display: grid;
  place-items: center;
}

.core {
  width: 15%;
  height: 30%;
}

.popover {
  margin-top: 16px;
  padding: 16px;
  position: fixed;
  text-align: center;
  backdrop-filter: saturate(150%) blur(10px);
  border-radius: 16px;
  background-color: rgba(127, 127, 127, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0 25px 20px -12px rgba(0, 0, 0, 0.15);
}

.slim {
  padding: 12px 16px;
}

.intro {
  font-family: var(--sans-font);
  font-size: 14px;
  line-height: 20px;
  color: white;
}

.inline-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-image: url('./click.svg')
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.color-settings {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.about {
  font-family: var(--sans-font);
  font-size: 12px;
  line-height: 16px;
  color: white;
}

.pickers {
  display: flex;
  gap: 16px;
}

.icon {
  display: block;
  margin: auto;
  padding-bottom: 12px;
  width: 34px;
  height: 24px;
}

.icon-small {
  width: 16px;
  height: 16px;
}

.popover .react-colorful {
  width: 120px;
  height: 140px;
  border-radius: 0px 0px 30px 10px;
  border: none;
}

.popover .react-colorful__saturation {
  border-bottom: 5px solid #000;
  box-shadow: none;
}

.popover .react-colorful__hue {
  height: 20px;
  border-radius: 0px;
}

.popover .react-colorful__saturation-pointer {
  width: 12px;
  height: 12px;
  border: 1px solid white;
}

.popover .react-colorful__hue-pointer {
  width: 2px;
  height: 16px;
  border-radius: 1px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.3),
    0 1px 1px 0 rgba(0, 0, 0, 0.2)
  ;
}

.input {
  margin: 0;
  width: 120px;
  padding: 8px;
  border: none;
  border-radius: 0px 0px 10px 10px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  outline: none;
  font-family: var(--mono-font);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  box-sizing: border-box;
}

::selection, p::selection, .input::selection, .about::selection, br::selection {
  color: black;
  background-color: white;
}

.popover-enter {
  transform: translateY(-200px);
  opacity: 0;
}

.popover-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms ease;
}

.popover-exit {
  transform: translateY(0);
  opacity: 1;
}

.popover-exit-active {
  transform: translateY(-200px);
  opacity: 0;
  transition: all 200ms ease;
}

.button {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  border-radius: 10px;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  font-family: var(--sans-font);
  font-size: 14px;
  line-height: 16px;
  color: white;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.button:active {
  background-color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 456px) {
  .popover .react-colorful {
    width: 92px;
    height: 112px;
  }
  .input {
    width: 92px;
  }
}